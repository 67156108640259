import Grid from "@material-ui/core/Grid";
import React from "react";
import {Box} from "@material-ui/core";

export const ColoredFont = ({ text, color }) => {
  const colorOptions = {
    red: "#ef4b4b",
    green: "#67b948",
    orange: "#f4861f",
  };
  return (
    <b style={{ textDecoration: "underline", color: colorOptions[color] }}>
      {text}
    </b>
  );
};
const footer = ({ pageType, title, classes }) => {
  if (title === "Student Population (Based Upon Continuous Enrollment)") {
    return null;
  }
  const footerHelper = (text) => {
    return (
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems="center"
        style={{ paddingTop: "10px" }}
      >
        <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
          <Grid item>
            <Box                                width={"2.5rem"}
                                                height={"2.5rem"}
              style={{ borderRadius: 0, marginRight: "1rem" }}
              className={classes.blueBackdrop}
            ></Box>{" "}
          </Grid>
          <Grid item>
            {" "}
            <span className={classes.legendIndex}>{text.blue}</span>
          </Grid>
        </Grid>
        <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
          <Grid item>
            <Box
                width={"2.5rem"}
                height={"2.5rem"}
              style={{ borderRadius: 0, marginRight: "1rem" }}
              className={classes.greenBackdrop}
            ></Box>
          </Grid>
          <Grid item>
            <span className={classes.legendIndex}>{text.green}</span>
          </Grid>
        </Grid>
        <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
          <Grid item>
            <Box
                width={"2.5rem"}
                height={"2.5rem"}
              style={{ borderRadius: 0, marginRight: "1rem" }}
              className={classes.yellowBackdrop}
            ></Box>
          </Grid>
          <Grid item>
            <span className={classes.legendIndex}>{text.yellow}</span>
          </Grid>
        </Grid>
        <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
          <Grid item>
            <Box
                width={"2.5rem"}
                height={"2.5rem"}
              style={{ borderRadius: 0, marginRight: "1rem" }}
              className={classes.orangeBackdrop}
            ></Box>
          </Grid>
          <Grid item>
            <span className={classes.legendIndex}>{text.orange}</span>
          </Grid>
        </Grid>
        <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
          <Grid item>
            <Box
                width={"2.5rem"}
                height={"2.5rem"}
              style={{ borderRadius: 0, marginRight: "1rem" }}
              className={classes.redBackdrop}
            ></Box>
          </Grid>
          <Grid item>
            <span className={classes.legendIndex}>{text.red}</span>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  switch (pageType) {
    case "LCAP":
      return footerHelper({
        blue: "Strongest Performance Or Change",
        green: "Strong Performance Or Change",
        yellow: "Medium Performance or Change",
        orange: "Weak Performance or Change",
        red: "Weakest Performance or Change",
      });

    case "OnTrackLanes":
      return footerHelper({
        blue: "Post-Secondary Competitive",
        green: "Post-Secondary Promising",
        yellow: "Post-Secondary Potential",
        orange: "Vulnerable",
        red: "Highly Vulnerable",
      });

    default:
      return (
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
            <Grid item>
              <Box
                  width={"2.5rem"}
                  height={"2.5rem"}
                  style={{ borderRadius: 0, marginRight: "1rem" }}
                  className={classes.greenBackdrop}
              ></Box>
            </Grid>
            <Grid item>
              <span className={classes.legendIndex}>
                Above Average (Index Levels 8, 9 and 10)
              </span>
            </Grid>
          </Grid>
          <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
            <Grid item>
              <Box
                  width={"2.5rem"}
                  height={"2.5rem"}
                  style={{ borderRadius: 0, marginRight: "1rem" }}
                  className={classes.orangeBackdrop}
              ></Box>
            </Grid>
            <Grid item>
              <span className={classes.legendIndex}>
                Average (Index Levels 4, 5, 6, 7)
              </span>
            </Grid>
          </Grid>
          <Grid alignItems={"center"} container xs={12} md={6} lg={4} item>
            <Grid item>
              <Box
                  width={"2.5rem"}
                  height={"2.5rem"}
                  style={{ borderRadius: 0, marginRight: "1rem" }}
                  className={classes.redBackdrop}
              ></Box>
            </Grid>
            <Grid item>
              <span className={classes.legendIndex}>
                Below Average (Index Levels 1, 2, 3)
              </span>
            </Grid>
          </Grid>
        </Grid>
      );
  }
};

export { footer };
