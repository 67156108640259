import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Logo from "../../static/UpdatedCoreLogo.png";
import { Nav_ALL } from "./constants";
import AppBar from "./AppBar";
import Logout from "./Logout";
import Main from "./Main";
import SecondaryMenu from "../../Menu/SecondaryMenu";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import { SideBarToolTip } from "../../Helpers/Sidebar/Sidebar";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

const drawerWidth = 230;

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      root: {
        display: "block !important",
      },
    },
  },
});

const styles = (theme) => ({
  menuContainer: {
    position: "fixed !important",
    zIndex: 50,
    top: 0,
    left: drawerWidth,
    display: "block !important",
    [theme.breakpoints.down("md")]: {
      left: 0,
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100vw - ${drawerWidth}px) !important`,
    },
  },
  appBarStyles: {},

  drawerStyles: {
    borderRight: "10px solid red",
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logo: {
    toolbar: theme.mixins.toolbar,
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "0px solid white",
    background: "rgba(0,0,0,0)",
    overflow: "visible",
    display: "flex !important",
  },
  sidebarStyleOverriding: {
    display: "flex !important",
    padding: `8px 16px 8px 16px !important`,
  },
  content: {
    flexGrow: 1,
    fontSize: "3.0em",
  },
  link: {
    textDecoration: "none",
  },
  selected: {
    height: "41px",
    background:
      "linear-gradient(90deg,rgba(244,134,31,.47),rgba(244,134,31,.11) 14.43%,rgba(244,134,31,.18) 74.48%,rgba(244,134,31,.47) 104.5%) !important",
  },
  subMenuStyle: {
    display: "flex !important",
  },
  menuItemText: {
    color: "rgba(1,61,84,.7)",
    fontWeight: "700",
    fontSize: "15px",
    display: "block !important",
  },
  secondItemText: {
    color: "rgba(1,61,84,.7)",
    fontSize: "15px",
  },
  arrow: {
    float: "right",
    marginRight: "-26px",
    height: "46px",
    width: "46px",
    transform: "rotate(45deg)",
    borderTop: "17.5px solid rgb(250,250,250)",
    borderRight: "17.5px solid rgb(250,250,250)",
  },
  iconStyles: {
    width: "1em !important",
  },
  selectedList: {},
  "@keyframes colorChange": {
    from: {
      backgroundImage:
        "radial-gradient(#fff 0,#fff 100%),url(https://dashboard.coredistricts.org/images/bg-texture.png);",
    },
    to: {
      backgroundImage:
        "radial-gradient(#fff 0,#edf7ff 100%),url(https://dashboard.coredistricts.org/images/bg-texture.png);",
    },
  },
  beta: {
    backgroundColor: "#ffee88",
    "&:hover": {
      backgroundColor: "#ffee88",
    },
    "&:active": {
      backgroundColor: "#ffee88",
    },
    "&:focus": {
      backgroundColor: "#ffee88",
    },
  },

  dashboardGuidance: {
    color: "#073763",
    fontWeight: "700",
    paddingTop: "20px",
    fontSize: "15px",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    textDecoration: "none",
    "$:hover": {
      cursor: "pointer",
    },
  },
});

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      showSelectEdit: "",
      mobileOpen: false,
      openLcap: false,
      openMeasures: false,
      openAnalytics: false,
      openLL: false,
      openExt: false,
      openPA: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleClickLcap = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openLcap: !state.openLcap,
      openMeasures: false,
      openAnalytics: false,
      // openPrelim: false,
      openLL: false,
      openExt: false,
      openPA: false,
    }));
  };

  handleClickMeasures = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openMeasures: !state.openMeasures,
      openLcap: false,
      openAnalytics: false,
      openPrelim: false,
      openLL: false,
      openExt: false,
      openPA: false,
    }));
  };

  handleClickAnalytics = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openAnalytics: !state.openAnalytics,
      openLcap: false,
      openMeasures: false,
      openPrelim: false,
      openLL: false,
      openExt: false,
      openPA: false,
    }));
  };

  handleClickOnTrack = () => {
    this.setState((state) => ({
      openOnTrack: !state.openOnTrack,
      openAnalytics: false,
      openLcap: false,
      openMeasures: false,
      openPrelim: false,
      openLL: false,
      openExt: false,
      openPA: false,
    }));
  };

  handleClickLL = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openLL: !state.openLL,
      openPrelim: false,
      openAnalytics: false,
      openLcap: false,
      openMeasures: false,
      openExt: false,
      openPA: false,
    }));
  };

  handleClickExt = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openExt: !state.openExt,
      openPrelim: false,
      openAnalytics: false,
      openLcap: false,
      openMeasures: false,
      openLL: false,
      openPA: false,
    }));
  };

  handleClickPA = () => {
    this.setState((state) => ({
      openOnTrack: false,
      openPA: !state.openPA,
      openPrelim: false,
      openAnalytics: false,
      openLcap: false,
      openMeasures: false,
      openLL: false,
      openExt: false,
    }));
  };

  handleToggleEdit = (key) => {
    const { showSelectEdit } = this.state;
    this.setState({
      showSelectEdit: !!showSelectEdit ? "" : key,
    });
  };

  /**
   *  isActiveSubMenu will take in value, and pathname from each menu/submenu
   *  component and if they match return true or false.
   **/
  isActiveSubMenu = (value, pathname) => {
    return value === pathname;
  };

  render() {
    const {
      classes,
      location: { pathname },
      path,
    } = this.props;
    const {
      mobileOpen,
      openLcap,
      openMeasures,
      openAnalytics,
      openOnTrack,
      openExt,
    } = this.state;
    const isPublic = pathname.split("/").reduce((isPublic, curr) => {
      if (curr === "public") {
        return true;
      }
      return isPublic;
    }, false);
    const publicDrawer = (
      <div>
        {/* **** LOGO NavHeader ***** */}
        <div className={classes.logo}>
          <img
            src={Logo}
            style={{
              paddingTop: "21px",
              paddingBottom: "13px",
              paddingLeft: "21px",
              width: "210px",
            }}
            alt=""
          />
        </div>
        <MenuList className={classes.menuItemText}>
          {/* **** Core Index NavMenuItem ***** */}

          <MenuItem
            component={NavLink}
            to={"/public/core"}
            selected={["/public", "/public/", "/public/core"].includes(
              pathname
            )}
            classes={{ selected: classes.selected }}
            className={classes.subMenuStyle}
          >
            <ListItemText>
              <span className={classes.menuItemText}>CORE Index</span>
            </ListItemText>
            {["/public", "/public/", "/public/core"].includes(pathname) ? (
              <div className={classes.arrow} />
            ) : null}
          </MenuItem>

          <Divider />
          {/* **** LCAP Dropdown NavMenuItems ***** */}
          <div className={openLcap ? classes.selectedList : null}>
            <MenuItem button onClick={this.handleClickLcap}>
              <ListItemText>
                <span className={classes.menuItemText}>LCAP</span>
              </ListItemText>
              {openLcap ? (
                <ExpandLess className={classes.iconStyles} />
              ) : (
                <ExpandMore className={classes.iconStyles} />
              )}
            </MenuItem>

            <Collapse in={openLcap} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[0].subMenu.map(({ name, id, value, publicValue }) => {
                  return (
                    <MenuItem
                      button
                      key={id}
                      component={NavLink}
                      to={isPublic ? publicValue : value}
                      selected={
                        isPublic
                          ? this.isActiveSubMenu(publicValue, pathname)
                          : this.isActiveSubMenu(value, pathname)
                      }
                      classes={{ selected: classes.selected }}
                      className={classes.subMenuStyle}
                    >
                      <ListItemText>
                        <span className={classes.secondItemText}>{name}</span>
                      </ListItemText>
                      {this.isActiveSubMenu(value, pathname) ? (
                        <div className={classes.arrow} />
                      ) : null}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>
          <Divider />
        </MenuList>
      </div>
    );
    const drawer = (
      <div>
        {/* **** LOGO NavHeader ***** */}
        <div className={classes.logo}>
          <img
            src={Logo}
            style={{
              paddingTop: "21px",
              paddingBottom: "13px",
              paddingLeft: "21px",
              width: "210px",
            }}
            alt=""
          />
        </div>
        <MenuList className={classes.menuItemText}>
          {/* **** Core Index NavMenuItem ***** */}

          <SideBarToolTip
            classes={classes}
            style={"menuItemText"}
            title={"CORE Index"}
            pathname={pathname}
            NavLink={NavLink}
            dropDown={false}
          />

          <Divider />
          {/* **** LCAP Dropdown NavMenuItems ***** */}
          <div className={openLcap ? classes.selectedList : null}>
            <SideBarToolTip
              classes={classes}
              title={"LCAP"}
              dropDown={true}
              onClick={this.handleClickLcap}
              openPrelim={openLcap}
            />

            <Collapse in={openLcap} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[0].subMenu.map(({ name, id, value, publicValue }) => {
                  return (
                    <MenuItem
                      button
                      key={id}
                      component={NavLink}
                      to={value}
                      selected={this.isActiveSubMenu(value, pathname)}
                      classes={{ selected: classes.selected }}
                      className={classes.subMenuStyle}
                    >
                      <ListItemText>
                        <span className={classes.secondItemText}>{name}</span>
                      </ListItemText>
                      {this.isActiveSubMenu(value, pathname) ? (
                        <div className={classes.arrow} />
                      ) : null}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>

          <Divider />
          {/* **** Academic Measures Dropdown NavMenuItems ***** */}
          <div className={openMeasures ? classes.selectedList : null}>
            {/*<MenuItem button onClick={this.handleClickMeasures}>*/}
            <SideBarToolTip
              classes={classes}
              title={"Growth Measures"}
              dropDown={true}
              onClick={this.handleClickMeasures}
              openPrelim={openMeasures}
            />

            <Collapse in={openMeasures} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[1].subMenu.map(({ name, id, value }) => {
                  return (
                    <MenuItem
                      button
                      key={id}
                      component={NavLink}
                      to={value}
                      selected={this.isActiveSubMenu(value, pathname)}
                      classes={{ selected: classes.selected }}
                      className={classes.subMenuStyle}
                    >
                      <ListItemText>
                        <span className={classes.secondItemText}>{name}</span>
                      </ListItemText>
                      {this.isActiveSubMenu(value, pathname) ? (
                        <div className={classes.arrow} />
                      ) : null}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>

          <Divider />
          {/* **** On-Demand Analytics Dropdown NavMenuItems ***** */}
          <div className={openAnalytics ? classes.selectedList : null}>
            <SideBarToolTip
              classes={classes}
              title={"On-Demand"}
              dropDown={true}
              onClick={this.handleClickAnalytics}
              openPrelim={openAnalytics}
            />

            <Collapse in={openAnalytics} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[2].subMenu.map(({ name, id, value }) => {
                  return (
                    <MenuItem
                      button
                      key={id}
                      component={NavLink}
                      to={value}
                      selected={this.isActiveSubMenu(value, pathname)}
                      classes={{ selected: classes.selected }}
                      className={classes.subMenuStyle}
                    >
                      <ListItemText>
                        <span className={classes.secondItemText}>{name}</span>
                      </ListItemText>
                      {this.isActiveSubMenu(value, pathname) ? (
                        <div className={classes.arrow} />
                      ) : null}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>

          <Divider />

          {/* **** On-Track Status ***** */}
          <div className={openOnTrack ? classes.selectedList : null}>
            <SideBarToolTip
              classes={classes}
              title={"On-Track Status"}
              dropDown={true}
              onClick={this.handleClickOnTrack}
              openPrelim={openOnTrack}
            />

            <Collapse in={openOnTrack} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[7].subMenu.map(({ name, id, value }) => {
                  return (
                    <MenuItem
                      button
                      key={id}
                      component={NavLink}
                      to={value}
                      selected={this.isActiveSubMenu(value, pathname)}
                      classes={{ selected: classes.selected }}
                      className={classes.subMenuStyle}
                    >
                      <ListItemText>
                        <span className={classes.secondItemText}>{name}</span>
                      </ListItemText>
                      {this.isActiveSubMenu(value, pathname) ? (
                        <div className={classes.arrow} />
                      ) : null}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>
          <Divider />
          {/* **** External Links Dropdown ***** */}

          <div>
            <SideBarToolTip
              classes={classes}
              title={"External Links"}
              dropDown={true}
              onClick={this.handleClickExt}
              openPrelim={openExt}
            />

            <Collapse in={openExt} timeout="auto" unmountOnExit>
              <MenuList component="div" disablePadding>
                {Nav_ALL[5].subMenu.map(({ name, id, value }) => {
                  return (
                    <MenuItem button key={id} className={classes.subMenuStyle}>
                      <a href={value} className={classes.link}>
                        <ListItemText>
                          <span className={classes.secondItemText}>{name}</span>
                        </ListItemText>
                      </a>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Collapse>
          </div>
          {/* **** End of NavMenuItems in MenuList ***** */}
        </MenuList>

        {/* **** Bottom of Nav Logout Button ***** */}
        <Logout />
      </div>
    );

    return (
      <div>
        <Grid container>
          <Grid
            className={classes.menuContainer}
            container
            item
            direction={"row"}
          >
            <Grid item xs={12}>
              <div
                style={{
                  background: "#e7baba",
                  fontSize: "1.25em",
                  padding: "1em",
                  fontWeight: 600,
                }}
                height="10em"
              >
                Notice: Due to ongoing issues with CALPADS extracts, files
                loaded after August 27th, 2024 may contain errors that have an
                impact on the posted results. In particular, the EL subgroups
                (EL, RFEP, EL-RFEP) should not be relied on. Once the issues are
                fixed and corrected files can be uploaded, the posted metrics
                may shift.
              </div>
              <AppBar
                toggleEdit={this.handleToggleEdit.bind(this)}
                handleDrawerToggle={this.handleDrawerToggle}
                path={path}
                className={classes.menuItemText}
              />
            </Grid>
            <Grid item xs={12}>
              <SecondaryMenu
                toggleEdit={this.handleToggleEdit.bind(this)}
                path={path}
              />
            </Grid>
          </Grid>
          <Grid style={{}} item xs={12}>
            <Main />
          </Grid>
        </Grid>

        <nav className={classes.drawerStyles}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Hidden mdUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              open={mobileOpen} //Starts the hamburger as closed (mobileOpen = false)
              onClose={this.handleDrawerToggle} //After burger is opened with onClick, this will toggle it closed again when clicking away
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="css">
            <MuiThemeProvider theme={theme}>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {isPublic ? publicDrawer : drawer}
              </Drawer>
            </MuiThemeProvider>
          </Hidden>
        </nav>
      </div>
    );
  }
}

const mapState = (state) => {
  const { user } = state.user;
  const betaOptIn =
    user.beta_features === "true" || user.beta_features === true;
  return {
    betaOptIn,
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true }),
  connect(mapState, mapDispatch)
)(SideBar);
